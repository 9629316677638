import React from "react";

function Hamburger({ color, hoverColor, active, onClick }) {
  return (
    <button className="menu-toggle-holder" onClick={() => onClick()}>
      <div className="menu-toggle">
        <div
          aria-hidden="true"
          className="menu-toggle-upper"
          style={{
            backgroundColor: color,
            transform: `${active ? "rotate(45deg)" : "translate(0, -0.6rem)"}`,
          }}
        ></div>
        <div
          aria-hidden="true"
          className="menu-toggle-middle"
          style={{ backgroundColor: color, opacity: active ? 0 : 100 }}
        ></div>
        <div
          aria-hidden="true"
          className="menu-toggle-lower"
          style={{
            backgroundColor: color,
            transform: `${active ? "rotate(-45deg)" : "translate(0, 0.6rem)"}`,
          }}
        ></div>
      </div>
    </button>
  );
}

export default Hamburger;
