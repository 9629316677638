import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { ConfigContext } from "../App";
import { fetchProductPageContent } from "../functions/apiCalls";
import { useLocation } from "react-router-dom";
import CarouselComponent from "../components/Carousel/Carousel";
import { dimColor, priceFormatter } from "../functions/utils";
import { BsFillCartDashFill } from "react-icons/bs";
import { BsCartPlusFill } from "react-icons/bs";
import Loading from "../components/Loading/Loading.js";
import { addToCart } from "../functions/addToCart.js";
import { TranslateContext } from "../App";
import config from "../configuration.json";

function Product() {
  const configuration = useContext(ConfigContext);
  const [product, setProduct] = useState(null);
  const location = useLocation();
  const slug = location.pathname.substring(1);
  const [carouselImages, setCarouselImages] = useState([]);
  const [hovered, setHovered] = useState(false);
  const [addedToCart, setAddedToCart] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const language = useContext(TranslateContext).language;

  useEffect(() => {
    fetchProductPageContent(slug).then((data) => {
      setIsLoading(true);
      data.id = data._id;
      setProduct(data);
      let images = [];
      if (data && data.images) {
        data.images.forEach((image) => {
          images.push({ src: image.asset.url, alt: data.title[language] });
        });
        setCarouselImages(images);
      }
      setIsLoading(false);
    });
  }, [slug]);

  useEffect(() => {
    if (product) {
      // Retrieve the cartItems array from local storage
      let cartItems = localStorage.getItem("cartItems");
      cartItems = cartItems ? JSON.parse(cartItems) : [];
      // Check if the current item is in the cart
      const isAddedToCart = cartItems.some((cartItem) => cartItem.id === product.id);
      // Update the addedToCart state based on the result
      setAddedToCart(isAddedToCart);
    } // Add this line
  }, [product]);

  const handleHover = (state) => {
    setHovered(state);
  };

  const handleAddToCart = () => {
    const isAddedToCart = addToCart(product);
    setAddedToCart(isAddedToCart);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    product && (
      <>
        <Helmet>
          <title>{product.title[language]}</title>
          <meta name="description" content={product.description[language]} />
        </Helmet>
        <div className="row">
          <div className="product">
            <CarouselComponent
              infiniteLoop={true}
              interval={3}
              images={carouselImages}
              axis={"horizontal"}
              autoPlay={false}
              bgColor={"white"}
              showArrows={true}
              height={"400px"}
              showIndicators={carouselImages.length > 1 ? true : false}
            />
            <div className="product-body">
              <div>
                <h1 className="h1-title text-start">{product.title[language]}</h1>
                <p>{product.description[language]}</p>
              </div>
              <div>
                <p className="product-price">{`${priceFormatter.format(product.price)} ${
                  config.texts.currency[language]
                }`}</p>
                <button
                  className="add-to-cart-button"
                  onMouseEnter={() => handleHover(true)}
                  onMouseLeave={() => handleHover(false)}
                  style={{
                    backgroundColor:
                      product.quantity === 0 && !addedToCart
                        ? "gray"
                        : hovered
                        ? dimColor(configuration.colors?.main?.hex, 20)
                        : configuration.colors?.main?.hex,
                  }}
                  disabled={product.quantity === 0 && !addedToCart}
                  onClick={handleAddToCart}
                >
                  {addedToCart ? <BsFillCartDashFill size={18} /> : <BsCartPlusFill size={18} />}
                  {addedToCart ? config.texts.remove[language] : config.texts.add[language]}
                </button>
                {product.quantity === 0 && (
                  <p className="product-not-available-label">{config.texts.productIsOutOfStock[language]}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
}

export default Product;
