import React, { useEffect, useState } from "react";
import StepperButton from "../StepperButton/StepperButton";
import "./Stepper.css";

function Stepper({ itemId, disableAdd, onAdd, onSubtract }) {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const handleStorageChange = () => {
      const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      const item = cartItems.find((item) => item.id === itemId);
      if (item) {
        setValue(item.cartQuantity);
      }
    };

    // Update value when component mounts
    handleStorageChange();

    // Update value when local storage changes
    window.addEventListener("storage", handleStorageChange);

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [itemId]);

  return (
    <div className="stepper">
      <StepperButton
        symbol={"-"}
        onClick={() => {
          onSubtract();
          setValue(value - 1);
        }}
      />
      <div className="stepper-value">{value}</div>
      <StepperButton
        symbol={"+"}
        onClick={() => {
          onAdd();
          setValue(value + 1);
        }}
        isDisabled={disableAdd}
      />
    </div>
  );
}

export default Stepper;
