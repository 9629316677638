import React, { useState } from "react";

const Newsletter = ({
  buttonColor,
  buttonHoverColor,
  buttonTextColor,
  checkboxColor,
  CTA,
  privacyPolicyURL,
  termsOfServiceURL,
}) => {
  const apiKey = process.env.REACT_APP_BREVO_API_KEY;
  const [email, setEmail] = useState("");
  const [hovered, setHovered] = useState(false);
  const handleMouseOver = () => {
    setHovered(true);
  };
  const handleMouseOut = () => {
    setHovered(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // regular expression for email validation
    const emailRegEx = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

    if (!emailRegEx.test(email)) {
      alert("Моля въведете валиден имейл адрес!");
      return;
    }

    const request = await fetch("https://api.brevo.com/v3/contacts", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": apiKey,
      },
      body: JSON.stringify({
        email: email,
        listIds: [4], // Brevo -> Contacts -> Lists -> list_id
      }),
    });

    const response = await request.json();

    if (request.ok) {
      alert("Успешно се записахте за нашия бюлетин!");
    } else {
      if (response.code === "duplicate_parameter") {
        alert("Този е-мейл вече съществува в нашите записи!");
      } else {
        alert("Възникна грешка! Моля опитайте отново по-късно.");
      }
    }
  };

  return (
    <form
      id="newsletter-form"
      className="newsletter-form"
      onSubmit={handleSubmit}
    >
      <div id="newsletter-form-inner" className="newsletter-form-inner">
        <label htmlFor="email">{CTA}</label>
        <input
          placeholder="Вашият e-mail"
          type="email"
          id="newsletter-form-input"
          name="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          required
          className="newsletter-form-input"
        />
        <button
          className="newsletter-form-submit-button"
          id="newsletter-form-submit-button"
          type="submit"
          style={{
            background: hovered ? buttonHoverColor : buttonColor,
            color: buttonTextColor,
          }}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          OK
        </button>
      </div>
      <div id="newsletter-form-terms" className="newsletter-form-terms">
        <div
          id="newsletter-form-terms-holder"
          className="newsletter-form-terms-holder"
        >
          <input
            id="newsletter-form-terms-checkbox-required-1"
            className="newsletter-form-terms-checkbox-required"
            type="checkbox"
            required
            style={{ accentColor: checkboxColor }}
          ></input>
          <label
            htmlFor="newsletter-form-terms-checkbox-required-1"
            className="small-paragraph"
          >
            Да, съгласен съм да получавам рекламна информация, касаеща нови
            продукти, текущи промоции, информационни текстове и всички други
            материали, свързани с предлаганите от нас услуги.
          </label>
        </div>
        <div
          id="newsletter-form-terms-holder"
          className="newsletter-form-terms-holder"
        >
          <input
            id="newsletter-form-terms-checkbox-required-2"
            className="newsletter-form-terms-checkbox-required"
            style={{ accentColor: checkboxColor }}
            type="checkbox"
            required
          ></input>
          <label
            htmlFor="newsletter-form-terms-checkbox-required-2"
            className="small-paragraph"
          >
            Прочетох и съм съгласен с{" "}
            {/* <span>
              <a href="/terms-and-conditions" className="underline font-bold">
                Условията за ползване
              </a>
            </span>{" "}
            и */}
            <span>
              {" "}
              <a href="/privacy-policy" className="underline font-bold">
                Политика за защита на личните данни
              </a>
            </span>
            .
          </label>
        </div>
      </div>
    </form>
  );
};

export default Newsletter;
