import React, { useContext } from "react";
import { priceFormatter } from "../../functions/utils";
import Stepper from "../Stepper/Stepper";
import InputForm from "../InputForm/InputForm";
import SubmitButton from "../Button/SubmitButton";
import config from "../../configuration.json";
import { TranslateContext } from "../../App";

function CheckoutItems({
  cartItems,
  onAdd,
  onSubtract,
  totalPrice,
  shippingPrice,
  handleSubmit,
  handleFormSubmit,
  formRef,
  register,
  errors,
  isMobile,
}) {
  const language = useContext(TranslateContext).language;
  return (
    <div className="checkout">
      <div className="row">
        <div className="checkout-product-list">
          <div className="checkout-product-list-header-row">
            <p></p> {/* Empty column for alignment */}
            <p>{config.texts.product[language]}</p>
            {!isMobile && <p>{config.texts.price[language]}</p>}
            <p>{config.texts.quantity[language]}</p>
            <p>{config.texts.total[language]}</p>
          </div>
          <hr />
          {cartItems.map((item, index) => (
            <React.Fragment key={index}>
              <div className="checkout-product-list-item" key={index}>
                <img className="checkout-product-list-item-image" src={item.image.src} alt={item.image.alt} />
                <a href={item.url} className="checkout-product-list-item-title">
                  {item.title[language]}
                </a>
                {!isMobile && (
                  <p className="checkout-product-list-item-unit-price">
                    {`${priceFormatter.format(parseFloat(item.price))} ${config.texts.currency[language]}`}{" "}
                  </p>
                )}
                <Stepper
                  key={item.id}
                  itemId={item.id}
                  disableAdd={item.cartQuantity === item.quantity}
                  onAdd={() => {
                    onAdd(item.id);
                  }}
                  onSubtract={() => {
                    onSubtract(item.id);
                  }}
                />
                <p className="checkout-product-list-item-total">{`${priceFormatter.format(
                  parseFloat(item.price) * item.cartQuantity
                )} ${config.texts.currency[language]}`}</p>
              </div>
              <hr />
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="row">
        <div className="checkout-contact-summary-container">
          <form
            id="checkoutForm"
            className="checkout-user-input-form"
            ref={formRef}
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <p className="checkout-user-input-form-title">{config.texts.shippingDetails[language]}</p>
            <InputForm register={register} errors={errors} />
          </form>
          <div className="checkout-v-divider" />
          <div className="checkout-summary">
            <div className="checkout-summary-card">
              <p>{config.texts.productsCost[language]}</p>
              <p>{`${priceFormatter.format(parseFloat(totalPrice))} ${config.texts.currency[language]}`}</p>
              <p className="checkout-summary-title">{config.texts.shippingCost[language]}</p>
              <p className="checkout-summary-title">{`${priceFormatter.format(shippingPrice)} ${
                config.texts.currency[language]
              }`}</p>
              <hr />
              <hr />
              <p className="checkout-summary-title-bold">{config.texts.totalCost[language]}</p>
              <p className="checkout-summary-title-bold">{`${priceFormatter.format(
                parseFloat(totalPrice) + Number(shippingPrice)
              )} ${config.texts.currency[language]}`}</p>
              <p>{config.texts.paymentMethod[language]}</p>
              <p>{config.texts.onSitePayment[language]}</p>
            </div>
            <div className="checkout-terms-check">
              <label className="checkout-terms-check-label">
                <input
                  className="checkout-terms-check-input"
                  type="checkbox"
                  {...register("termsAndConditions", { required: true })}
                />
                <p>
                  {config.texts.readAndAgreeWith[language]}{" "}
                  <a className="italic" href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                    {config.texts.termsAndConditions[language]}
                  </a>{" "}
                  и{" "}
                  <a className="italic" href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                    {config.texts.privacyPolicy[language]}
                  </a>
                  .
                </p>
              </label>{" "}
              {errors.termsAndConditions && (
                <span className="checkout-terms-error">{config.texts.pleaseAgreeWithError[language]}</span>
              )}
              <SubmitButton type={"large"} onClick={handleSubmit(handleFormSubmit)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutItems;
