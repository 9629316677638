import sanityClient from "../CMS/client";
import imageUrlBuilder from "@sanity/image-url";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { ImPhone } from "react-icons/im";
import { AiFillClockCircle } from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";
import { RiInstagramFill } from "react-icons/ri";
import { FaTiktok } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { BiSolidShoppingBag } from "react-icons/bi";

const urlFor = imageUrlBuilder(sanityClient);

export const getImageSource = (ref) => {
  return urlFor.image(ref).auto("format").fit("max").toString();
};

export async function fetchData(request) {
  try {
    const data = await sanityClient.fetch(request);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function patchData(productId, quantity) {
  sanityClient
    .patch(productId)
    .dec({ quantity: quantity })
    .commit()
    .catch((err) => {
      console.error("Oh no, the update failed: ", err.message);
    });
}

export const createSocialMediaArray = (socialMediaFromSettings) => {
  if (socialMediaFromSettings) {
    return Object.entries(socialMediaFromSettings).map(([name, url]) => ({
      name,
      url,
      type: "social",
    }));
  } else {
    return [];
  }
};

export const createNavItemsArray = async (navigation) => {
  const createSubmenuItems = (subMenuItems) => {
    return subMenuItems.map((subItem) => ({
      name: subItem.title,
      url: `/${subItem.slug}`,
      type: "page",
    }));
  };

  const navItemsArray = navigation
    ? navigation.map((navItem) => {
        const item = {
          name: navItem.item.title,
          url: `/${navItem.item.slug}`,
          type: "page",
          submenu: navItem.subMenuItems ? createSubmenuItems(navItem.subMenuItems) : [],
        };

        return item;
      })
    : [];

  return navItemsArray;
};

export const createContactsArray = (contactsFromSettings) => {
  return contactsFromSettings
    ? Object.entries(contactsFromSettings).map(([icon, text]) => ({
        icon,
        text,
      }))
    : [];
};

export function setSiteName(name) {
  document.title = name;
}

export function dimColor(hexColor, dimPercentage) {
  // Remove the '#' symbol if present
  if (hexColor !== undefined) {
    hexColor = hexColor.replace("#", "");
    // Convert the hex color to RGB
    const red = parseInt(hexColor.substring(0, 2), 16);
    const green = parseInt(hexColor.substring(2, 4), 16);
    const blue = parseInt(hexColor.substring(4, 6), 16);

    // Calculate the dimmed RGB values
    const dimFactor = 1 - dimPercentage / 100;
    const dimmedRed = Math.floor(red * dimFactor);
    const dimmedGreen = Math.floor(green * dimFactor);
    const dimmedBlue = Math.floor(blue * dimFactor);

    // Convert the dimmed RGB values back to hex
    const dimmedHex = `#${dimmedRed.toString(16).padStart(2, "0")}${dimmedGreen
      .toString(16)
      .padStart(2, "0")}${dimmedBlue.toString(16).padStart(2, "0")}`;

    return dimmedHex;
  }
  return;
}

export function mapContacts(contactsArray, iconColor) {
  return contactsArray.map((contact) => {
    let url = undefined;
    if (contact.icon === "phone") {
      url = `tel:${contact.text}`;
    } else if (contact.icon === "email") {
      url = `mailto:${contact.text}`;
    } else if (contact.icon === "shop") {
      url = `https://${contact.text}`;
    }

    const text = (url) => {
      if (url !== undefined) {
        return (
          <a href={url} target="_blank" rel="noreferrer">
            {contact.text}
          </a>
        );
      } else {
        return <p>{contact.text}</p>;
      }
    };

    return (
      <div className="contact-holder" key={contact.text}>
        {setIcon(22, contact.icon, iconColor)}
        {text(url)}
      </div>
    );
  });
}

export function mapMenu(menuItemsArray) {
  return menuItemsArray.map((menuItem, index) => {
    if (menuItem.type === "page") {
      return (
        <a href={menuItem.url} key={`menu-item-${index}`}>
          <p className="normal-paragraph">{menuItem.name}</p>
        </a>
      );
    } else {
      return null;
    }
  });
}

export function setIcon(size, type, color) {
  const types = {
    address: FaMapMarkerAlt,
    email: MdEmail,
    phone: ImPhone,
    workTime: AiFillClockCircle,
    shop: BiSolidShoppingBag,
    facebook: BsFacebook,
    instagram: RiInstagramFill,
    tiktok: FaTiktok,
    twitter: BsTwitter,
    youtube: BsYoutube,
  };

  const Icon = types[type];
  const socialMedias = ["facebook", "instagram", "tiktok", "twitter", "youtube"];

  if (socialMedias.some((media) => media === type)) {
    return <Icon color={color} className="link-to-social-media" size={size} />;
  } else {
    return <Icon className="size-[22px]" fill={color} />;
  }
}

export async function checkProductQuantity(productId) {
  // replace this with your actual Sanity client and query

  const query = `*[_type == "product" && _id == $productId][0]{
    quantity
  }`;
  const params = { productId };

  const product = await sanityClient.fetch(query, params);
  return product.quantity;
}

export const priceFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
