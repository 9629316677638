import React, { useState, useEffect, useContext } from "react";
import { dimColor } from "../../functions/utils";
import "./Button.css";
import { ConfigContext } from "../../App";
import textConfig from "../../configuration.json";
import { TranslateContext } from "../../App";

function SubmitButton({ type, onClick }) {
  const configuration = useContext(ConfigContext);
  const [hovered, setHovered] = useState(false);
  const [buttonClass, setButtonClass] = useState("");
  const [buttonClassInner, setButtonClassInner] = useState("");
  const language = useContext(TranslateContext).language;

  useEffect(() => {
    if (type === "small") {
      setButtonClass("button-sm");
      setButtonClassInner("button-sm-inner");
    } else if (type === "medium") {
      setButtonClass("button-md");
      setButtonClassInner("button-md-inner");
    } else if (type === "large") {
      setButtonClass("button-lg");
      setButtonClassInner("button-lg-inner");
    } else {
      setButtonClass("button-md");
      setButtonClassInner("button-md-inner");
    }
  }, [configuration, type]);

  function handleHover(state) {
    setHovered(state);
  }

  return (
    <div
      className={buttonClass}
      style={{
        backgroundColor: hovered ? dimColor(configuration.colors?.main?.hex, 20) : configuration.colors?.main?.hex,
      }}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      <button className={buttonClassInner} onClick={onClick}>
        {textConfig.texts.submitOrder[language]}
      </button>
    </div>
  );
}

export default SubmitButton;
