import React, { useState, useEffect, createContext } from "react";
import Header from "./components/Header/Header.js";
import Footer from "./components/Footer/Footer.js";
import { fetchConfiguration, fetchHomePageContent } from "./functions/apiCalls.js";
import { useMediaQuery } from "react-responsive";
import { createContactsArray } from "./functions/utils.js";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { defaultLanguage } from "./multilanguage.js";

import Cart from "./components/Cart/Cart.js";
import Home from "./pages/Home.js";
import Store from "./pages/Store.js";
import Blog from "./pages/Blog.js";
import NotFound from "./pages/NotFound.js";
import Product from "./pages/Product.js";
import Page from "./pages/Page.js";
import Checkout from "./pages/Checkout.js";
import PrivacyPolicy from "./pages/PrivacyPolicy.js";

export const ConfigContext = createContext();
export const NavigationContext = createContext();
export const TranslateContext = createContext();

function App() {
  const isMobile = useMediaQuery({ maxWidth: 1023 });

  const [configuration, setConfiguration] = useState({});
  const [navigation, setNavigation] = useState([]);
  const [headerLogo, setHeaderLogo] = useState("");
  const [footerLogo, setFooterLogo] = useState("");
  const [contacts, setContacts] = useState([]);
  const [homePageContent, setHomePageContent] = useState([]);
  const [pageTypesSlugs, setPageTypesSlugs] = useState([]);
  const [language, setLanguage] = useState(defaultLanguage);

  function CartWrapper() {
    const location = useLocation();
    return location.pathname !== "/checkout" ? <Cart /> : null;
  }

  useEffect(() => {
    // Fetch and set configuration & navigation data
    const fetchAndSetConfiguration = async () => {
      const response = await fetchConfiguration();
      setConfiguration(response.configuration);
      setNavigation(response.navigation);

      // Set header and footer logos
      setHeaderLogo(response.configuration?.headerLogo?.asset?.url);
      setFooterLogo(response.configuration?.footerLogo?.asset?.url);

      // Set contacts data in Footer
      setContacts(createContactsArray(response.configuration?.businessInfo));

      // Set page types and slugs
      setPageTypesSlugs(response.allPages);
    };

    // Fetch and set home page content
    const fetchAndSetHomePageContent = async () => {
      const response = await fetchHomePageContent();
      setHomePageContent(response);
    };

    fetchAndSetConfiguration();
    fetchAndSetHomePageContent();
  }, []);

  const helmetContext = {};

  return (
    <BrowserRouter>
      <TranslateContext.Provider value={{ language, setLanguage }}>
        <HelmetProvider context={helmetContext}>
          <ConfigContext.Provider value={configuration}>
            <NavigationContext.Provider value={navigation}>
              <div className="app">
                <Header
                  mainColor={configuration?.colors?.main?.hex}
                  secondaryColor={configuration?.colors?.secondary?.hex}
                  bgColor={"white"}
                  logoSrc={headerLogo}
                  menu={navigation}
                  isMobile={isMobile}
                  mobileMenuWidth={"100%"}
                />
                <CartWrapper />
                <Routes>
                  <Route element={<Home content={homePageContent} configuration={configuration} />} path="/" exact />
                  <Route element={<Checkout />} path="/checkout" />
                  {pageTypesSlugs.length > 0 &&
                    pageTypesSlugs.map((page) => {
                      let Element;
                      switch (page._type) {
                        case "store":
                          Element = Store;
                          break;
                        case "blog":
                          Element = Blog;
                          break;
                        case "product":
                          Element = Product;
                          break;
                        case "blogpost":
                        case "page":
                          Element = Page;
                          break;
                        default:
                          Element = NotFound;
                      }
                      return <Route key={page.slug} exact path={`/${page.slug}`} element={<Element />} />;
                    })}
                  <Route exath path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path={pageTypesSlugs.length > 0 ? "*" : ""} element={<NotFound />} />
                </Routes>
                <Footer
                  iconColor={configuration?.colors?.secondary?.hex}
                  bgColor={configuration?.colors?.main?.hex}
                  textColor={"#c2c1c1"}
                  logoSrc={footerLogo}
                  contacts={contacts}
                  menu={navigation}
                  copyrightText={configuration?.rightsReserved?.[language]}
                  copyrightTextBackgroundColor={"#F7FAFD"}
                  hasNewsletter={configuration?.hasNewsletter}
                  newsLetterCTA={
                    "Запиши се за нашия бюлетин, за да получаваш преждевременни известия за всички новости в сайта и магазина!"
                  }
                  newsletterButtonColor={"#98DDF4"}
                  newsletterButtonHoverColor={"#E4F3FD"}
                  checkboxColor={configuration?.colors?.secondary?.hex}
                  privacyPolicyURL={"/privacy-policy"}
                />
              </div>
            </NavigationContext.Provider>
          </ConfigContext.Provider>
        </HelmetProvider>
      </TranslateContext.Provider>
    </BrowserRouter>
  );
}

export default App;
