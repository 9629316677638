import Newsletter from "./Newsletter";
import { mapContacts } from "../../functions/utils";
import { mapMenu } from "../../functions/utils";
import "./Footer.css";
import config from "../../configuration.json";
import { TranslateContext } from "../../App";
import { useContext } from "react";

function Footer({
  iconColor,
  bgColor,
  textColor,
  logoSrc,
  contacts,
  menu,
  copyrightText,
  copyrightTextBackgroundColor,
  hasNewsletter,
  newsletterCTA,
  newsletterButtonColor,
  newsletterButtonHoverColor,
  checkboxColor,
  privacyPolicyURL,
  termsOfServiceURL,
}) {
  const language = useContext(TranslateContext).language;
  const filteredContacts = contacts.map((contact) => {
    if (typeof contact.text === "object" && contact.text !== null) {
      return { ...contact, text: contact.text[language] };
    }
    return contact;
  });
  const filterMenu = (menu) => {
    return menu.map((item) => {
      let newItem = { ...item };
      if (typeof newItem.name === 'object' && newItem.name !== null) {
        newItem.name = newItem.name[language];
      }
      if (newItem.submenu) {
        newItem.submenu = filterMenu(newItem.submenu);
      }
      return newItem;
    });
  };
  
  const filteredMenu = filterMenu(menu);

  return (
    <footer className="footer" id="footer">
      <div id="footer-inner" className="footer-inner" style={{ backgroundColor: bgColor, color: textColor }}>
        <div id="footer-container" className="footer-container">
          <div id="footer-contacts" className="footer-contacts">
            <a href="/">
              <img id="footer-logo" className="footer-logo" src={logoSrc} alt="logo"></img>
            </a>
            <div id="footer-contacts-holder" className="footer-contacts-holder">
              {mapContacts(filteredContacts, iconColor)}
            </div>
          </div>
          <div id="footer-navigation" className="footer-navigation">
            <h4 className="h4-title text-start" style={{ color: textColor }}>
              {config.texts.information[language]}
            </h4>
            <div id="footer-navigation-holder" className="footer-navigation-holder">
              <a href="/">
                <p className="normal-paragraph">{config.texts.home[language]}</p>
              </a>
              {mapMenu(filteredMenu)}
            </div>
          </div>
          {hasNewsletter && (
            <div id="footer-newsletter" className="footer-newsletter">
              <h4 className="h4-title text-start" style={{ color: textColor }}>
                {config.texts.newsletter[language]}
              </h4>
              <Newsletter
                buttonColor={newsletterButtonColor}
                buttonHoverColor={newsletterButtonHoverColor}
                buttonTextColor={textColor}
                checkboxColor={checkboxColor}
                CTA={newsletterCTA}
                privacyPolicyURL={privacyPolicyURL}
                termsOfServiceURL={termsOfServiceURL}
              />
            </div>
          )}
        </div>
      </div>
      <div
        id="copyright-text"
        className="copyright-text"
        style={{
          backgroundColor: copyrightTextBackgroundColor,
          color: textColor,
        }}
      >
        <div id="copyright-text-inner" className="copyright-text-inner">
          {copyrightText}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
