import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet-async";
import Row from "../components/Row/Row";
import { useLocation } from "react-router-dom";
import { fetchPageContent } from "../functions/apiCalls";
import Loading from "../components/Loading/Loading.js";
import { TranslateContext } from "../App.js";

function Page() {
  const location = useLocation();
  const slug = location.pathname.substring(1);
  const [rows, setRows] = useState([]);
  const [content, setContent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const language = useContext(TranslateContext).language;
  useEffect(() => {
    fetchPageContent("blogpost", slug).then((data) => {
      setIsLoading(true);
      setContent(data);
      setIsLoading(false);
    });
  }, [slug]);

  useEffect(() => {
    if (content && content.rows) {
      setRows(content.rows);
    }
  }, [content]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    content && (
      <>
        <Helmet>
          <title>{content.title[language]}</title>
          <meta name="description" content={content.metaDescription} />
        </Helmet>
        <div className="home w-screen min-h-[60vh] max-h-fit">
          <h2 className="page-title">{content.title[language]}</h2>
          {rows.map((row, index) => {
            return <Row columns={row.columns} bgColor={row.background.color} key={`row-${index}`} />;
          })}
        </div>
      </>
    )
  );
}

export default Page;
