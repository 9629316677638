export function addToCart(product) {
  // Retrieve the cartItems array from local storage
  let cartItems = localStorage.getItem("cartItems");
  cartItems = cartItems ? JSON.parse(cartItems) : [];

  // Check if the item is already in the cart
  const existingItemIndex = cartItems.findIndex((cartItem) => cartItem.id === product.id);

  if (existingItemIndex !== -1) {
    // If the item is in the cart, remove it
    cartItems.splice(existingItemIndex, 1);
  } else {
    // If the item is not in the cart, add it with a cartQuantity of 1
    // Change the structure of the image object
    const productToAdd = {
      ...product,
      cartQuantity: 1,
      image: { alt: null, src: product.images ? product.images[0].asset.url : product.image.src },
      images: undefined,
    };
    cartItems.push(productToAdd);
  }

  // Stringify the updated array and store it back in local storage
  localStorage.setItem("cartItems", JSON.stringify(cartItems));

  // Dispatch a custom event after updating localStorage
  window.dispatchEvent(new CustomEvent("cartItemsUpdated"));

  // Return whether the product is in the cart after the operation
  return cartItems.some((cartItem) => cartItem.id === product.id);
}
