import React from "react";
import { PortableText } from "@portabletext/react";
import "./Paragraph.css"

function Text({ content, color, align }) {
  return (
    <div className="portable-text" style={{ color: color, textAlign: align }}>
      <PortableText value={content} />
    </div>
  );
}

export default Text;
