import React, { useEffect, useState, useContext } from "react";
import Text from "../Paragraph/Text";
import { ConfigContext } from "../../App";
import { TranslateContext } from "../../App";

function TitleSection({ title, titleTag, titleJustify, colors, subtitleText, subtitleJustify }) {
  const configuration = useContext(ConfigContext);
  const language = useContext(TranslateContext).language;
  const [titleColor, setTitleColor] = useState("");
  const [subtitleColor, setSubtitleColor] = useState("");

  useEffect(() => {
    if (colors.title === "primary") {
      setTitleColor(configuration?.colors?.main?.hex);
    } else if (colors.title === "secondary") {
      setTitleColor(configuration?.colors?.secondary?.hex);
    } else {
      setTitleColor(colors.title);
    }

    if (colors.subtitle === "primary") {
      setSubtitleColor(configuration?.colors?.main?.hex);
    } else if (colors.subtitle === "secondary") {
      setSubtitleColor(configuration?.colors?.secondary?.hex);
    } else {
      setSubtitleColor(colors.subtitle);
    }
  }, [colors.title, colors.subtitle, configuration]);

  const tagMapping = {
    h1: "h1",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    h5: "h5",
  };

  const selectedTag = tagMapping[titleTag] || "h1";

  return (
    <div className="title-section">
      <div className="title-holder" style={{ color: titleColor, textAlign: titleJustify }}>
        {React.createElement(selectedTag, { className: `${selectedTag}-title` }, title[language])}
      </div>
      {subtitleText[language] && (
        <div className="subtitle-holder">
          <Text content={subtitleText[language]} color={subtitleColor} align={subtitleJustify} />
        </div>
      )}
    </div>
  );
}

export default TitleSection;
