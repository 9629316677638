import React, { useContext } from "react";
import config from "../../configuration.json";
import { TranslateContext } from "../../App";
import "./Popup.css";

function Popup({ title, body, isOpen, setIsOpen, configuration }) {
  const language = useContext(TranslateContext).language;
  if (!isOpen) {
    return null;
  }

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="alert-popup">
      <div className="alert-popup-inner">
        <h4 className="alert-title">{title}</h4>
        <p className="alert-message">{body}</p>
        <button
          className="alert-buttons"
          onClick={handleClose}
          style={{ borderColor: configuration?.colors?.main.hex, color: configuration?.colors?.main.hex }}
        >
          {config.texts.close[language]}
        </button>
      </div>
      <div className="alert-backdrop"></div>
    </div>
  );
}

export default Popup;
