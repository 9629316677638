import React, { useContext } from "react";
import Image from "../Image/Image";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./Carousel.css";
import { TranslateContext } from "../../App";

function CarouselComponent({
  autoPlay,
  height,
  images,
  axis,
  infiniteLoop,
  interval,
  stopOnHover,
  showArrows,
  showIndicators,
}) {
  const language = useContext(TranslateContext).language;

  return (
    <Carousel
      axis={axis}
      autoPlay={autoPlay}
      infiniteLoop={infiniteLoop}
      interval={interval * 1000}
      stopOnHover={stopOnHover}
      swipeable={true}
      showArrows={showArrows}
      showStatus={false}
      showThumbs={false}
      showIndicators={showIndicators}
      renderItem={(item, { isPrevious, isSelected, ...props }) => (
        <div style={{ height: height }} {...props}>
          {item}
        </div>
      )}
    >
      {images.map((image, index) => {
        return (
          <Image key={`carousel-image-${index}`} source={image.src} altText={image.alt?.[language]} expandable={false} />
        );
      })}
    </Carousel>
  );
}

export default CarouselComponent;
