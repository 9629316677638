import React, { useContext } from "react";
import Input from "../Input/Input";
import config from "../../configuration.json";
import { TranslateContext } from "../../App";

function InputForm({ register, errors }) {
  const language = useContext(TranslateContext).language;
  return (
    <>
      <Input
        register={register}
        errors={errors}
        name="firstName"
        validation={{ required: config.texts.firstNameValidation[language] }}
        placeholder={config.texts.firstName[language]}
      />
      <Input
        register={register}
        errors={errors}
        name="lastName"
        validation={{ required: config.texts.lastNameValidation[language] }}
        placeholder={config.texts.lastName[language]}
      />
      <Input
        register={register}
        errors={errors}
        name="email"
        validation={{
          required: config.texts.emailValidation[language],
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: config.texts.invalidEmail[language],
          },
        }}
        placeholder={config.texts.email[language]}
      />
      <Input
        register={register}
        errors={errors}
        name="phoneNumber"
        validation={{
          required: config.texts.phoneValidation[language],
          pattern: {
            value: /^(\+359|0)[0-9]{9}$/,
            message: config.texts.invalidPhone[language],
          },
        }}
        placeholder={config.texts.phone[language]}
      />
      <Input
        register={register}
        errors={errors}
        name="address"
        validation={{ required: config.texts.addressValidation[language] }}
        placeholder={config.texts.address[language]}
      />
      <div className="flex flex-row gap-4">
        <Input
          register={register}
          errors={errors}
          name="city"
          validation={{ required: config.texts.cityValidation[language] }}
          placeholder={config.texts.city[language]}
        />
        <Input
          register={register}
          errors={errors}
          name="postalCode"
          validation={{
            required: config.texts.postCodeValidation[language],
            pattern: {
              value: /^[0-9]{4}$/,
              message: config.texts.invalidPostCode[language],
            },
          }}
          placeholder={config.texts.postCode[language]}
        />
      </div>
      <Input
        register={register}
        errors={errors}
        name="additionalInfo"
        placeholder={config.texts.additionalInfo[language]}
      />
    </>
  );
}

export default InputForm;
