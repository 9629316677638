import React, { useContext } from "react";
import { TranslateContext } from "../../App";
import config from "../../configuration.json";

function ThankYou({ configuration, isMobile, orderedItems, orderedTotal, priceFormatter, shippingPrice, userDetails }) {
  const language = useContext(TranslateContext).language;
  return (
    <div className="row">
      <div className="thank-you-section">
        <h2 className="h2-title">{config.texts.orderConfirmation[language]}</h2>
        <p>
          {config.texts.orderConfirmationText1[language]}
          <br />
          {config.texts.orderConfirmationText2[language]}
        </p>
        <p>
          {config.texts.orderConfirmationText3[language]}{" "}
          <a className="font-bold" href={`tel:${configuration?.businessInfo?.phone}`}>
            {configuration?.businessInfo?.phone}
          </a>
          <br />
          {config.texts.orderConfirmationText4[language]}{" "}
          <a className="font-bold" href={`mailto:${configuration?.businessInfo?.email}`}>
            {configuration?.businessInfo?.email}
          </a>
        </p>
        <hr />
        <h3 className="h3-title">{config.texts.orderSummary[language]}</h3>
        <div className="order-summary-container">
          <div className="order-summary">
            <div className="order-summary-row">
              <p></p> {/* Empty column for alignment */}
              <p>{config.texts.product[language]}</p>
              <p>{config.texts.quantity[language]}</p>
              <p>{config.texts.price[language]}</p>
            </div>
            <hr />
            {orderedItems.map((item, index) => (
              <React.Fragment key={index}>
                <div key={index}>
                  <div className="order-summary-row">
                    <img className="order-item-image" src={item.image.src} alt={item.image.alt} />
                    <a href={`/${item.slug}`} className="otder-item-title">
                      {item.title}
                    </a>
                    <p className="lg:text-lg">{`${item.cartQuantity} ${config.texts.pcs[language]}.`} </p>
                    <p className="order-item-total">{`${priceFormatter.format(
                      parseFloat(item.price) * item.cartQuantity
                    )} ${config.texts.currency[language]}`}</p>
                  </div>
                </div>
                <hr />
              </React.Fragment>
            ))}
            <div className="order-summary-price-breakdown-container">
              <div className="order-summary-price-breakdown">
                <p>{config.texts.shippingCost[language]}</p>
                <p>{`${priceFormatter.format(shippingPrice)} ${config.texts.currency[language]}`}</p>
                <p>{config.texts.total[language]}</p>
                <p className="order-summary-price-breakdown-total">{`${priceFormatter.format(
                  parseFloat(orderedTotal) + Number(shippingPrice)
                )} ${config.texts.currency[language]}`}</p>
              </div>
            </div>
          </div>
          <div className="order-summary-contact-details">
            {!isMobile && <div className="checkout-h-divider"></div>}
            <div className="order-summary-contact-details-inner">
              <h4 className="h4-title">{config.texts.shippingDetails[language]}</h4>
              <p>{`${userDetails?.firstName} ${userDetails?.lastName}`}</p>
              <p>{userDetails?.email}</p>
              <p>{userDetails?.phoneNumber}</p>
              <p>{userDetails?.address}</p>
              <p>{userDetails?.city}</p>
              <p>{userDetails?.postalCode}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThankYou;
