import React, { useContext, useState, useEffect, useRef } from "react";
import { ConfigContext } from "../App";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { patchData, checkProductQuantity, priceFormatter } from "../functions/utils";
import emailjs from "emailjs-com";
import { useMediaQuery } from "react-responsive";
import { tokens } from "../CMS/client";
import ThankYou from "../components/Checkout/ThankYou";
import CheckoutItems from "../components/Checkout/CheckoutItems";
import Popup from "../components/Popup/Popup";
import config from "../configuration.json";
import { TranslateContext } from "../App";

function Checkout() {
  const language = useContext(TranslateContext).language;
  emailjs.init(tokens.emailjs_init);
  const emailjs_service_id = tokens.emailjs_service_id;
  const emailjs_template_id_client = tokens.emailjs_template_id_client;
  const emailjs_template_id_summary = tokens.emailjs_template_id_summary;
  const [popup, setPopup] = useState({ isOpen: false, title: "", body: "" });
  const [cartItems, setCartItems] = useState([]);
  const [orderedItems, setOrderedItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [orderedTotal, setOrderedTotal] = useState(0);
  const [checkedOut, setCheckedOut] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const isMobile = useMediaQuery({ maxWidth: 1023 });
  const configuration = useContext(ConfigContext);
  const formRef = useRef();
  const shippingPrice = 10;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /**
   * Handles form submission.
   * @param {Object} data - The form data.
   */
  const handleFormSubmit = async (data) => {
    const updatedCartItems = await checkProductAvailability(cartItems);
    if (!updatedCartItems) {
      return;
    }

    setUserDetails(data);
    setCheckedOut(true);
    handleCheckout(data, updatedCartItems);

    // Dispatch a custom event after updating localStorage
    window.dispatchEvent(new CustomEvent("cartItemsUpdated"));
  };

  /**
   * Checks the availability of each product in the cart.
   * @param {Array} cartItems - The items in the cart.
   * @returns {Array} The updated cart items.
   */
  const checkProductAvailability = async (cartItems) => {
    let updatedCartItems = [...cartItems];
    for (let i = 0; i < updatedCartItems.length; i++) {
      const availableQuantity = await checkProductQuantity(updatedCartItems[i].id);

      if (availableQuantity < updatedCartItems[i].cartQuantity) {
        setPopup({
          isOpen: true,
          title: config.texts.oops[language],
          body: `${config.texts.selectedQuantity[language]} "${updatedCartItems[i].title}", ${config.texts.isMoreThanAvailable[language]} (${availableQuantity} ${config.texts.pcsLeft[language]}). ${config.texts.pleaseChangeQuantity[language]}`,
        });
        return null;
      }
    }
    return updatedCartItems;
  };

  /**
   * Handles the checkout process.
   * @param {Object} data - The form data.
   * @param {Array} updatedCartItems - The updated cart items.
   */
  const handleCheckout = (data, updatedCartItems) => {
    // Update the cart items in the state and local storage
    setCartItems(updatedCartItems);
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));

    // Update the quantity of each ordered item
    updatedCartItems.forEach((item) => {
      patchData(item.id, item.cartQuantity);
    });

    // Clear the local storage from the cart items
    localStorage.removeItem("cartItems");

    // Set orderedItems
    setOrderedItems(cartItems);

    // Clear the cart items from the state
    setCartItems([]);

    const orderedItemsSummary = updatedCartItems
      .map(
        (item) => `
    <tr>
      <td style="width: 20%; padding: 10px; text-align: center;"><img src="${item.image.src}" alt="${item.title}" style="width: 100px; height: 100px; object-fit: cover;"></td>
      <td style="width: 20%; padding: 10px; text-align: center;">${item.title}</td>
      <td style="width: 20%; padding: 10px; text-align: center;">${item.cartQuantity}</td>
      <td style="width: 20%; padding: 10px; text-align: center;">${item.price} ${config.texts.currency[language]}</td>
      <td style="width: 20%; padding: 10px; text-align: center;"><a href="${configuration.businessInfo.shop}/${item.url}">Виж повече</a></td>
    </tr>
  `
      )
      .join("");

    const tableHTML = `
    <table style="width: 100%; border-collapse: collapse; table-layout: auto;">
      <thead style="background-color: lightgray;">
        <tr>
          <th style="border: 1px solid black; padding: 10px; width: 20%; text-align: center;"></th>
          <th style="border: 1px solid black; padding: 10px; width: 20%; text-align: center;">Продукт</th>
          <th style="border: 1px solid black; padding: 10px; width: 20%; text-align: center;">К-во</th>
          <th style="border: 1px solid black; padding: 10px; width: 20%; text-align: center;">Ед. цена</th>
          <th style="border: 1px solid black; padding: 10px; width: 20%; text-align: center;">Линк</th>
        </tr>
      </thead>
      <tbody>
        ${orderedItemsSummary}
      </tbody>
    </table>
  `;

    // Send an email to the client and the store owner
    const templateParams = {
      client_name: data.firstName,
      client_last_name: data.lastName,
      client_phone: data.phoneNumber,
      to_email: data.email,
      from_email: configuration.businessInfo.email,
      order_details: tableHTML,
      store_phone: configuration.businessInfo.phone,
      store_website: configuration.businessInfo.shop,
      reply_to: configuration.businessInfo.email,
      store_name: configuration.title,
      user_details: {
        city: data.city,
        address: data.address,
        postalCode: data.postalCode,
      },
      shipping_price: `${priceFormatter.format(shippingPrice)} ${config.texts.currency[language]}`,
      order_total: `${priceFormatter.format(parseFloat(orderedTotal) + Number(shippingPrice))} ${
        config.texts.currency[language]
      }`,
    };

    try {
      emailjs.send(emailjs_service_id, emailjs_template_id_client, templateParams);
      emailjs.send(emailjs_service_id, emailjs_template_id_summary, templateParams);
    } catch (error) {
      console.error("FAILED...", error);
    }
  };

  /**
   * Adds an item to the cart.
   * @param {string} itemId - The ID of the item to add.
   */
  const onAdd = (itemId) => {
    const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    const item = cartItems.find((item) => item.id === itemId);
    if (item) {
      item.cartQuantity += 1;
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
      setCartItems(cartItems);
    }
    window.dispatchEvent(new Event("cartItemsUpdated"));
  };

  /**
   * Subtracts an item from the cart.
   * @param {string} itemId - The ID of the item to subtract.
   */
  const onSubtract = (itemId) => {
    let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    const itemIndex = cartItems.findIndex((item) => item.id === itemId);
    if (itemIndex !== -1) {
      if (cartItems[itemIndex].cartQuantity > 1) {
        // Create a new array with the updated item
        cartItems = cartItems.map((item) =>
          item.id === itemId ? { ...item, cartQuantity: item.cartQuantity - 1 } : item
        );
      } else {
        // Create a new array without the item
        cartItems = cartItems.filter((item) => item.id !== itemId);
      }
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
      setCartItems(cartItems); // Update the local state
      window.dispatchEvent(new Event("cartItemsUpdated"));
    }
  };

  useEffect(() => {
    let total = 0;
    for (let item of cartItems) {
      total += item.price * item.cartQuantity;
    }
    if (cartItems.length > 0) {
      setOrderedTotal(total);
    }
    setTotalPrice(total);
  }, [cartItems]);

  useEffect(() => {
    const storedCartItems = localStorage.getItem("cartItems");
    if (storedCartItems) {
      const parsedItems = JSON.parse(storedCartItems);
      setCartItems(parsedItems);
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>{configuration.title}</title>
      </Helmet>
      <>
        {cartItems.length === 0 && !checkedOut && (
          <div className="row">
            <div className="row-container">
              <h5 className="h5-title text-center w-full">{config.texts.cardEmpty[language]}</h5>
            </div>
          </div>
        )}
        {orderedItems.length > 0 && checkedOut && (
          <ThankYou
            configuration={configuration}
            isMobile={isMobile}
            orderedItems={orderedItems}
            orderedTotal={orderedTotal}
            priceFormatter={priceFormatter}
            shippingPrice={shippingPrice}
            userDetails={userDetails}
          />
        )}
        {cartItems.length > 0 && (
          <CheckoutItems
            cartItems={cartItems}
            onAdd={onAdd}
            onSubtract={onSubtract}
            totalPrice={totalPrice}
            shippingPrice={shippingPrice}
            handleFormSubmit={handleFormSubmit}
            handleSubmit={handleSubmit}
            formRef={formRef}
            register={register}
            errors={errors}
            isMobile={isMobile}
          />
        )}
        <Popup
          title={popup.title}
          body={popup.body}
          isOpen={popup.isOpen}
          setIsOpen={(isOpen) => setPopup((prev) => ({ ...prev, isOpen }))}
          configuration={configuration}
        />
      </>
    </>
  );
}
export default Checkout;
