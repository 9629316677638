import React from "react";
import "./Input.css";

function Input({ register, errors, name, validation, placeholder }) {
  return (
    <div className="input-container">
      <input className="input" {...register(name, validation)} id={name} />
      <label htmlFor={name} className="input-label">
        {placeholder}
      </label>
      {errors[name] && <span className="input-validation-error">{errors[name].message}</span>}
    </div>
  );
}

export default Input;
