import React, { useContext } from "react";
import { TbError404 } from "react-icons/tb";
import { ConfigContext } from "../App";

function NotFound() {
  const configuration = useContext(ConfigContext);
  return (
    configuration && (
      <div className="flex flex-col items-center justify-center">
        <TbError404 size={120} color={configuration.colors?.secondary?.hex} />
        <h3 className="h3-title">Упс, изглежда, че тази страница не съществува.</h3>
      </div>
    )
  );
}

export default NotFound;
