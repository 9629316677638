import NavItem from "./NavItem";

function MenuModal({
  navItems,
  color,
  hoverColor,
  bgColor,
  active,
  mobileMenuWidth,
}) {
  const Menu = () => {
    return navItems.map((item) => {
      return (
        <NavItem
          key={`nav-item-${item.name}`}
          item={item}
          color={color}
          hoverColor={hoverColor}
          isMobile={true}
        />
      );
    });
  };

  return (
    <div
      className="mobile-menu-modal-inner"
      style={{
        right: active ? 0 : "-100vw",
        width: mobileMenuWidth,
        backgroundColor: bgColor,
      }}
    >
      <Menu />
    </div>
  );
}

export default MenuModal;
