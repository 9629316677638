import React, { useEffect, useState, useContext } from "react";
import Column from "../Column/Column";
import { ConfigContext } from "../../App";
import "./Row.css";

function Row({ columns, bgColor }) {
  const configuration = useContext(ConfigContext);
  const colors = configuration?.colors;
  const [backgroundColor, setBackgroundColor] = useState("white");

  useEffect(() => {
    const bgColors = {
      gray: "#F7FAFD",
      primary: colors?.main?.hex,
      secondary: colors?.secondary?.hex,
    };

    setBackgroundColor(bgColors[bgColor] || "white");
  }, [bgColor, colors]);

  return (
    <section className="row" style={{ backgroundColor: backgroundColor }}>
      <div className="row-container">
        {columns.map((column, index) => (
          <Column key={`column-${index}`} component={column} colors={colors} />
        ))}
      </div>
    </section>
  );
}

export default Row;
