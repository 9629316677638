import React, { useEffect, useContext, useState } from "react";
import Text from "../components/Paragraph/Text.js";
import { ConfigContext } from "../App.js";
import { fetchData } from "../functions/utils.js";
import { pageContentGROQ } from "../functions/GROQ.js";
import { renderCardsSection } from "../functions/renderComponent.js";
import { Helmet } from "react-helmet-async";
import Loading from "../components/Loading/Loading.js";
import { TranslateContext } from "../App.js";

function Blog() {
  const configuration = useContext(ConfigContext);
  const [pageContent, setPageContent] = useState({});
  const [featured, setFeatured] = useState(null);
  const [allBlogposts, setAllBlogposts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const language = useContext(TranslateContext).language;

  useEffect(() => {
    const fetchAndSetPageContent = async () => {
      setIsLoading(true);
      const response = await fetchData(pageContentGROQ("blog"));
      setPageContent(response);
      if (response.blogposts) {
        const blogposts = {
          cardNewsSection: response.blogposts,
          cardType: "blog",
          hasFilter: true,
        };
        setAllBlogposts(await renderCardsSection(blogposts, false, false, language));
      }

      if (response.blog && response.blog.featured) {
        let featuredSection = {
          title: null,
          button: { link: null, size: "medium", text: null },
          cardNewsSection: [],
          cardType: "blog",
          hasFilter: false,
        };
        if (response.blog.featured.title) {
          featuredSection.title = response.blog.featured.title[language];
        }
        if (response.blog.featured.button) {
          if (response.blog.featured.button.link) {
            featuredSection.button.link = response.blog.featured.button.link;
          }
          if (response.blog.featured.button.text) {
            featuredSection.button.text = response.blog.featured.button.text[language];
          }
        }
        if (response.blog.featured.blogposts) {
          featuredSection.cardNewsSection = response.blog.featured.blogposts;
        }
        setFeatured(await renderCardsSection(featuredSection, true, true, language));
        setIsLoading(false);
      }
    };

    fetchAndSetPageContent();
  }, [language]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>{pageContent.blog?.title[language]}</title>
        <meta name="description" content={pageContent.blog?.metaDescription[language]} />
      </Helmet>
      <div className="row">
        <div className="row-container">
          <div className="column">
            <h1 className="h1-title" style={{ color: configuration.colors.secondary.hex }}>
              {pageContent.blog?.title[language]}
            </h1>
            {pageContent.blog?.onPageDescription && (
              <Text
                content={pageContent.blog?.onPageDescription[language]}
                align={pageContent.blog?.onPageDescription?.justify}
              />
            )}
            {allBlogposts}
            {featured}
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog;
