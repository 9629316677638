import { useState, useContext } from "react";
import { setIcon } from "../../../functions/utils";
import SubNavItem from "./SubNavItem";
import { TranslateContext } from "../../../App";
import config from "../../../configuration.json";

function NavItem({ item, color, hoverColor, bgColor, isMobile }) {
  const [hovered, setHovered] = useState(false);
  const [submenuActive, setSubmenuActive] = useState(false);
  const { language } = useContext(TranslateContext);

  const toggleSubmenu = (state) => {
    if (item.submenu) {
      setSubmenuActive(state);
    }
  };

  const handleMouseEnter = () => {
    if (!isMobile) {
      setHovered(true);
      toggleSubmenu(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setHovered(false);
      toggleSubmenu(false);
    }
  };

  const handleOnClick = (e) => {
    if (isMobile && item.submenu.length > 0) {
      setSubmenuActive(!submenuActive);
    } else {
      window.location.href = item.url;
    }
  };

  const Submenu = ({ items }) => {
    if (items) {
      return items.map((item) => (
        <SubNavItem
          key={item.name[language]}
          color={color}
          hoverColor={hoverColor}
          url={item.url}
          text={item.name[language]}
          isMobile={isMobile}
        />
      ));
    } else {
      return null;
    }
  };

  const provideURL = () => {
    if (isMobile) {
      if (item.submenu) {
        return null;
      } else {
        return item.url;
      }
    }
    if (item.submenu && isMobile) {
      return null;
    } else {
      return item.url;
    }
  };

  return (
    <>
      {item.type === "page" ? (
        <button
          className="nav-item"
          style={{
            color: hovered ? hoverColor : color,
          }}
          onClick={handleOnClick}
        >
          <a className="nav-item-a" href={provideURL()} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <span className="hidden">Link to {item.url}</span>
          </a>
          {submenuActive && item.submenu.length > 0 && (
            <div
              className="submenu"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={{ backgroundColor: bgColor }}
            >
              <Submenu items={item.submenu} />
              {isMobile && (
                <SubNavItem
                  color={color}
                  hoverColor={isMobile ? null : hoverColor}
                  url={item.url}
                  text={config.texts.see_all[language]}
                />
              )}
            </div>
          )}
          {item.name[language]}
        </button>
      ) : item.type === "social" ? (
        <a
          style={{
            color: hovered ? hoverColor : color,
          }}
          className="nav-item"
          href={item.url}
          target="_blank"
          rel="noreferrer"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <span className="hidden">Link to {item.url}</span>
          {setIcon(isMobile ? 40 : 26, item.name, hovered ? hoverColor : color)}
        </a>
      ) : null}
    </>
  );
}

export default NavItem;
