import React, { useState, useContext } from "react";
import Card from "./Card";
import Button from "../Button/Button";
import "./Cards.css";
import "./style.css";
import { ConfigContext } from "../../App";
import { TranslateContext } from "../../App";
import textConfig from "../../configuration.json";

function CardsSection({ title, featured, cardsType, cardsContent, button, hasFilter }) {
  const configuration = useContext(ConfigContext);
  const language = useContext(TranslateContext).language;
  const cardSectionClass = cardsType === "product" ? "product-card-section" : "card-section";
  const featuredCardSectionClass = cardsType === "product" ? "featured-product-cards" : "featured-cards";

  let categories = [];
  if (cardsContent && cardsContent.length > 0) {
    categories = cardsContent.map((card) => card.category?.title || undefined);
  }
  const uniqueCategories = categories.filter((category, index, self) => category && self.indexOf(category) === index);

  const [selectedCategory, setSelectedCategory] = useState("Всички категории");
  const [selectedFilter, setSelectedFilter] = useState("Най-нови");

  let filteredCards =
    selectedCategory === "Всички категории"
      ? cardsContent
      : cardsContent.filter((card) => card.category?.title === selectedCategory);

  switch (selectedFilter) {
    case "Цена (възходяща)":
      filteredCards = filteredCards.sort((a, b) => a.price - b.price);
      break;
    case "Цена (низходяща)":
      filteredCards = filteredCards.sort((a, b) => b.price - a.price);
      break;
    case "А-Я":
      filteredCards = filteredCards.sort((a, b) => a.title.localeCompare(b.title));
      break;
    case "Я-А":
      filteredCards = filteredCards.sort((a, b) => b.title.localeCompare(a.title));
      break;
    case "Най-нови":
      filteredCards = filteredCards.sort((a, b) => b.createdAt?.localeCompare(a.createdAt));
      break;
    case "Най-стари":
      filteredCards = filteredCards.sort((a, b) => a.createdAt?.localeCompare(b.createdAt));
      break;
    default:
      break;
  }

  return (
    <div className="card-section-container">
      {title && (
        <div className="card-section-title-container">
          <h2 className="h2-title">{title}</h2>
        </div>
      )}
      {hasFilter && (
        <div className="filter">
          <select className="filter-drop-down" onChange={(e) => setSelectedFilter(e.target.value)}>
            <option value={textConfig.texts.filter[language]}>{textConfig.texts.filter[language]}</option>
            <option value={textConfig.texts.newest[language]}>{textConfig.texts.newest[language]}</option>
            <option value={textConfig.texts.oldest[language]}>{textConfig.texts.oldest[language]}</option>
            {cardsType !== "blog" && (
              <>
                <option value={textConfig.texts.priceLowToHigh[language]}>
                  {textConfig.texts.priceLowToHigh[language]}
                </option>
                <option value={textConfig.texts.priceHighToLow[language]}>
                  {textConfig.texts.priceHighToLow[language]}
                </option>
              </>
            )}
            <option value={textConfig.texts.alphabetical[language]}>{textConfig.texts.alphabetical[language]}</option>
            <option value={textConfig.texts.alphabeticalReversed[language]}>
              {textConfig.texts.alphabeticalReversed[language]}
            </option>
          </select>
          <select className="filter-drop-down" onChange={(e) => setSelectedCategory(e.target.value)}>
            <option value={textConfig.texts.allCategories[language]}>{textConfig.texts.allCategories[language]}</option>
            {uniqueCategories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
      )}
      <div className={featured ? featuredCardSectionClass : cardSectionClass}>
        {filteredCards &&
          filteredCards.map((card, index) => {
            return <Card key={`card-${index}`} type={cardsType} content={card} configuration={configuration} />;
          })}
      </div>
      {button && button.text && (
        <div className="card-section-button-container">
          <Button
            type={button.size}
            text={button.text}
            configuration={configuration}
            url={button.link}
            openInNewTab={button.openInNewTab}
          />
        </div>
      )}
    </div>
  );
}

export default CardsSection;
