import React, { useState, useRef, useCallback, useEffect } from "react";
import LogoHolder from "./components/LogoHolder";
import Navigation from "./components/Navigation";
import MenuModal from "./components/MenuModal";
import "./Header.css";
import LanguagePicker from "./components/LanguagePicker";
import { hasMultilanguage } from "../../multilanguage";

function Header({ mainColor, secondaryColor, bgColor, logoSrc, menu, isMobile, mobileMenuWidth }) {
  const [headerVisible, setHeaderVisible] = useState(true);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);

  let prevScrollY = useRef(window.scrollY);
  const handleScroll = useCallback(() => {
    const currentScrollPos = window.scrollY;
    if (!mobileMenuActive) {
      setHeaderVisible(currentScrollPos <= 150 || currentScrollPos <= prevScrollY.current);
    }
    prevScrollY.current = currentScrollPos;
  }, [mobileMenuActive]);

  useEffect(() => {
    document.body.style.overflow = mobileMenuActive ? "hidden" : "auto";
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [mobileMenuActive, handleScroll]);

  function handleOnClick() {
    setMobileMenuActive(!mobileMenuActive);
  }

  return (
    <header id="header" className="header" style={{ top: headerVisible ? 0 : -154 }}>
      <div id="header-inner" className="header-inner" style={{ backgroundColor: bgColor }}>
        <div id="header-inner-container" className="header-inner-container">
          <div id="header-inner-logo" className="header-inner-logo">
            <LogoHolder src={logoSrc} color={mainColor} hoverColor={secondaryColor} />
          </div>
          <div id="header-inner-navigation" className="header-inner-navigation">
            <Navigation
              navItems={menu}
              color={mainColor}
              hoverColor={secondaryColor}
              isMobile={isMobile}
              bgColor={bgColor}
              mobileMenuActive={mobileMenuActive}
              onClick={handleOnClick}
            />
            {hasMultilanguage && <LanguagePicker />}
          </div>
        </div>
      </div>
      {isMobile && (
        <MenuModal
          active={mobileMenuActive}
          navItems={menu}
          color={mainColor}
          hoverColor={secondaryColor}
          bgColor={bgColor}
          mobileMenuWidth={mobileMenuWidth}
        />
      )}
    </header>
  );
}

export default Header;
