import React, { useContext, useState } from "react";
import { ConfigContext } from "../../App";
import { dimColor } from "../../functions/utils";
import "./StepperButton.css";

function StepperButton({ symbol, isDisabled, onClick }) {
  const configuration = useContext(ConfigContext);
  const [isHovered, setIsHovered] = useState(false);

  function handleHover(state) {
    setIsHovered(state);
  }

  const backgroundColor = isDisabled
    ? "gray"
    : isHovered
    ? dimColor(configuration.colors?.secondary?.hex, 20)
    : configuration.colors?.secondary?.hex;

  return (
    <button
      disabled={isDisabled}
      className="stepper-button"
      onClick={onClick}
      style={{ backgroundColor }}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      {symbol}
    </button>
  );
}

export default StepperButton;
