import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet-async";
import Row from "../components/Row/Row";
import { ConfigContext } from "../App";
import Loading from "../components/Loading/Loading.js";

function Home({ content }) {
  const configuration = useContext(ConfigContext);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const setRowsContent = () => {
      setIsLoading(true);
      if (content.rows) {
        setRows(content.rows);
      }
      setIsLoading(false);
    };
    setRowsContent();
  }, [content]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>{configuration.title}</title>
        <meta name="description" content={content.metaDescription} />
      </Helmet>
      <div className="home">
        {rows.map((row, index) => {
          return <Row columns={row.columns} bgColor={row.background.color} key={`row-${index}`} />;
        })}
      </div>
    </>
  );
}

export default Home;
