export const configurationGROQ = `{"settings":*[_type == "siteSettings"][0]{
  title,
  businessInfo,
  rightsReserved,
  socialMedia,
  footerLogo{asset-> {url}},
  headerLogo{asset-> {url}},
  colors{main{hex},secondary{hex}},
  hasNewsletter
  },
  "navigation":*[_type == "navigation"][0]{
  menu[]{
    "item":page->{slug,title},
    subMenuItems[]->{slug,title}
  }
  },
  "allPages": *[_type in ["page","product","blog","store","blogpost"]]{
  slug,
  _type
  }
}`;

export const homePageGROQ = `*[_type == "home"][0] {
  metaDescription,
  "rows":content[]
}`;

export const batchImageSourcesGROQ = (imageRefs) => {
  return `*[_id in [${imageRefs.map((ref) => `"${ref}"`).join(", ")}]] { "url": url }`;
};

export const batchBlogPostCardsGROQ = (refs) => {
  return `*[_id in [${refs.map((ref) => `"${ref}"`).join(", ")}]]{
    metaDescription,
      title,
      slug,
      category->{"title":title,"color":color.hex},
      publishDate,
      coverImage{asset->{url}},
  } | order(publishDate desc, title asc)`;
};

export const batchProductCardsGROQ = (refs) => {
  return `*[_id in [${refs.map((ref) => `"${ref}"`).join(", ")}]]{
    description,
      category->{"title":title,"color":color.hex},
      title,
      "image":images[0]{asset->{url}},
      price,
      slug
  }`;
};

export const pageContentGROQ = (type, slug) => {
  let request;
  switch (type) {
    case "home":
      request = `*[_type == "home"][0] {
        metaDescription,
        "rows":content[]
      }`;
      break;

    case "product":
      request = `*[_type=="product" && slug == "${slug}"][0] {
        category->{"title":title,"color":color.hex},
        title,
        price,
        quantity,
        slug,
        description,
        "images":images[]{asset->{url}},
        _createdAt,
        _id
      }`;
      break;

    case "blog":
      request = `{"blog": *[_type=="blog"][0]{
        title,
          featured,
          slug,
          onPageDescription,
          metaDescription
      },
      "blogposts": *[_type=="blogpost"]{
        _createdAt,
        _type,
        _updatedAt,
        publishDate,
        slug,
        title,
        category->{"title":title,"color":color.hex},
        coverImage{asset->{url}},
      }
      }`;
      break;

    case "store":
      request = `{"store": *[_type=="store"][0]{
        slug,
        featured{
          button,
          title,
          products[]->{
            category->{"title":title,"color":color.hex},
            title,
            price,
            slug,
            description,
            "image":images[0]{asset->{url}},
            _createdAt
          }
        },
        onPageDescription,
        metaDescription,
        title,
      },
      "products": *[_type=="product"]{
        category->{"title":title,"color":color.hex},
        title,
        price,
        quantity,
        slug,
        description,
        "image":images[0]{asset->{url}},
        _createdAt,
        _id
    }}`;
      break;

    case "page":
    case "blogpost":
      request = `*[_type in ["page","blogpost"] && slug == "${slug}"][0] {
        _createdAt,
        _type,
        _updatedAt,
        publishDate,
        slug,
        title,
        category->{"title":title,"color":color.hex},
        coverImage{asset->{url}},
        metaDescription,
        "rows":content[]
      }`;
      break;
      
    default:
      request = null;
  }
  return request;
};
