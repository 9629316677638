import CarouselComponent from "../components/Carousel/Carousel";
import TitleSection from "../components/TitleSection/TitleSection";
import CardsSection from "../components/CardsSection/CardsSection";
import Gallery from "../components/Gallery/Gallery";
import Text from "../components/Paragraph/Text";
import Button from "../components/Button/Button";
import Image from "../components/Image/Image";
import { getImageSource, fetchData } from "../functions/utils";
import { batchImageSourcesGROQ, batchBlogPostCardsGROQ, batchProductCardsGROQ } from "../functions/GROQ";
import Map from "../components/Map/Map";

function extractKeyFromUrl(url) {
  const parts = url.split("/");
  return parts[parts.length - 1].split("-")[0];
}

function extractKeyFromRef(ref) {
  return ref.split("-")[1];
}

export const renderCarousel = async (props, height) => {
  const images = props.content.map((imageObject) => ({
    src: null,
    alt: imageObject.alt,
    ref: imageObject.image.asset._ref,
  }));

  const imageRefs = images.map((image) => image.ref);

  const imageSources = await fetchData(batchImageSourcesGROQ(imageRefs));

  const urlMap = imageSources.reduce((map, source) => {
    const key = extractKeyFromUrl(source.url);
    map[key] = source.url;
    return map;
  }, {});

  images.forEach((image) => {
    const refKey = extractKeyFromRef(image.ref);
    if (urlMap.hasOwnProperty(refKey)) {
      image.src = urlMap[refKey];
    }
  });
  return (
    <CarouselComponent
      autoPlay={true}
      height={height}
      images={images}
      axis={"horizontal"}
      infiniteLoop={true}
      interval={3}
      stopOnHover={true}
      showArrows={false}
    />
  );
};

export const renderTitleSection = (props) => {
  let titleColor = props.titleColor;
  let subtitleColor = props.subtitle.subtitleColor;
  return (
    <TitleSection
      title={props.title}
      titleTag={props.titleTag}
      titleJustify={props.justify}
      colors={{ title: titleColor, subtitle: subtitleColor }}
      subtitleText={props.subtitle.multilanguageText}
      subtitleJustify={props.subtitle.multilanguageText.justify}
    />
  );
};

export const renderCardsSection = async (props, isFeatured, needFetch, language) => {
  let cardsType = props.cardType;
  let cardsContentPopulated = [];

  if (cardsType === "general") {
    const cards = props.cardBasicSection.map((cardProp) => ({
      image: {
        src: null,
        alt: cardProp.image?.alt?.[language],
        ref: cardProp.image?.image.asset._ref,
      },
      url: cardProp.link,
      title: cardProp.title[language],
    }));

    const imageRefs = cards.map((card) => card.image.ref);
    const imageSources = await fetchData(batchImageSourcesGROQ(imageRefs));

    const urlMap = imageSources.reduce((map, source) => {
      const key = extractKeyFromUrl(source.url);
      map[key] = source.url;
      return map;
    }, {});

    cards.forEach((card) => {
      const refKey = extractKeyFromRef(card.image.ref);
      if (urlMap.hasOwnProperty(refKey)) {
        card.image.src = urlMap[refKey];
      }
      cardsContentPopulated.push(card);
    });
  } else if (cardsType === "blog") {
    let blogPosts = [];
    if (needFetch) {
      const blogRefs = [];
      for (const card of props.cardNewsSection) {
        blogRefs.push(card._ref);
      }

      blogPosts = await fetchData(batchBlogPostCardsGROQ(blogRefs));
    } else {
      blogPosts = props.cardNewsSection;
    }
    for (const post of blogPosts) {
      let blogCard = {
        image: { src: null, alt: null },
        title: null,
        url: null,
        publishDate: null,
        category: { title: null, color: null },
      };

      if (post.coverImage) {
        blogCard.image.src = post.coverImage.asset.url;
      } else {
        blogCard.image.src = "";
      }

      blogCard.image.alt = post.title[language];

      if (post.title) {
        blogCard.title = post.title[language];
      }

      if (post.slug) {
        blogCard.url = `/${post.slug}`;
      }

      blogCard.publishDate = post.publishDate;

      if (post.category) {
        blogCard.category = {
          title: post.category.title[language],
          color: post.category.color,
        };
      }

      cardsContentPopulated.push(blogCard);
    }
  } else if (cardsType === "product") {
    const productRefs = [];
    let products;
    if (props.cardProductSection[0]._ref) {
      for (const card of props.cardProductSection) {
        productRefs.push(card._ref);
      }
      products = await fetchData(batchProductCardsGROQ(productRefs));
    } else {
      products = props.cardProductSection;
    }
    for (const product of products) {
      let productObj = {
        image: { src: null, alt: null },
        title: null,
        url: null,
        price: null,
        description: null,
        category: { title: null, color: null },
        createdAt: null,
      };
      if (product.image) {
        productObj.image.src = product.image.asset.url;
      } else {
        productObj.image.src = "";
      }
      if (product.title) {
        productObj.title = product.title[language];
      }
      if (product.slug) {
        productObj.url = `/${product.slug}`;
      }
      if (product.price) {
        productObj.price = product.price;
      }
      if (product.description) {
        productObj.description = product.description[language];
      }
      if (product.category) {
        productObj.category = {
          title: product.category.title[language],
          color: product.category.color,
        };
      }
      productObj.createdAt = product._createdAt;
      cardsContentPopulated.push(productObj);
    }
  } else if (cardsType === "product-sm") {
    const products = props.cardProductSection;
    for (const product of products) {
      let productObj = {
        image: { src: null, alt: null },
        title: null,
        url: null,
        price: null,
        category: { title: null, color: null },
        createdAt: null,
        id: null,
        quantity: null,
      };
      if (product.image) {
        productObj.image.src = product.image.asset.url;
      } else {
        productObj.image.src = "";
      }
      if (product.title) {
        productObj.title = product.title[language];
      }
      if (product.slug) {
        productObj.url = `/${product.slug}`;
      }
      if (product.price) {
        productObj.price = product.price;
      }
      if (product.description) {
        productObj.description = product.description[language];
      }
      if (product.category) {
        productObj.category = {
          title: product.category.title[language],
          color: product.category.color,
        };
      }
      productObj.quantity = product.quantity;
      productObj.id = product._id;
      productObj.createdAt = product._createdAt;
      cardsContentPopulated.push(productObj);
    }
  }

  let sectionButton = {
    size: null,
    text: null,
    openInNewTab: false,
    link: null,
  };

  if (props.button?.link) {
    sectionButton.link = props.button.link;
  }

  if (props.button?.openInNewTab) {
    sectionButton.openInNewTab = true;
  }

  sectionButton.size = props.button?.size;
  sectionButton.text = props.button?.text?.[language];

  return (
    cardsContentPopulated && (
      <CardsSection
        title={props.title?.[language]}
        featured={isFeatured}
        cardsType={cardsType}
        cardsContent={cardsContentPopulated}
        hasFilter={props.hasFilter}
        button={sectionButton}
      />
    )
  );
};

export const renderGallery = async (props) => {
  let imageRefs = [];
  for (const imageProp of props.content) {
    if (imageProp.image?.asset?._ref) {
      imageRefs.push(imageProp.image.asset._ref);
    }
  }
  const imageSources = await fetchData(batchImageSourcesGROQ(imageRefs));
  const images = [];
  for (let i = 0; i < imageSources.length; i++) {
    let image = { source: null, alt: null };
    image.source = imageSources[i].url;
    image.alt = props.content[i].alt;
    images.push(image);
  }
  return <Gallery images={images} />;
};

export const renderImage = async (props, language) => {
  let image = { source: null, alt: null };
  if (props.image?.asset?._ref) {
    image.source = await getImageSource(props.image.asset._ref);
  }
  if (props.alt) {
    image.alt = props.alt?.[language];
  }
  return <Image source={image.source} altText={image.alt} expandable={true} />;
};

export const renderParagraph = (props, language) => {
  return <Text content={props[language]} align={props.justify} color={"black"} />;
};

export const renderButton = (props, colors, language) => {
  return (
    <Button
      openInNewTab={props.openInNewTab}
      type={props.size}
      text={props.text[language]}
      url={props.link}
      colors={colors}
    />
  );
};

export const renderMap = (props) => {
  return <Map lat={props.lat} lng={props.lng} zoom={props.zoom} />;
};
