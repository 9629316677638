import React, { useState, useEffect, useContext } from "react";
import {
  renderCarousel,
  renderTitleSection,
  renderCardsSection,
  renderGallery,
  renderImage,
  renderParagraph,
  renderButton,
  renderMap,
} from "../../functions/renderComponent";
import { useMediaQuery } from "react-responsive";
import { TranslateContext } from "../../App";

function Column({ component, colors }) {
  const [columnElements, setColumnElements] = useState([]);
  const [columnProps, setColumnProps] = useState({
    width: "100%",
    align: "center",
  });
  const isMobile = useMediaQuery({ maxWidth: 1023 });
  const language = useContext(TranslateContext).language;

  useEffect(() => {
    const renderComponents = async () => {
      const componentType = component?._type;
      let newColumnElements = [];

      if (componentType === "column") {
        for (const element of component.content) {
          const elementType = element._type;

          if (elementType === "paragraphComponent") {
            newColumnElements.push(renderParagraph(element, language));
          } else if (elementType === "imageComponent") {
            newColumnElements.push(await renderImage(element, language));
          } else if (elementType === "buttonComponent") {
            newColumnElements.push(renderButton(element, colors, language));
          } else if (elementType === "mapComponent") {
            newColumnElements.push(renderMap(element));
          }
        }

        const colWidth = component.width;
        const colElementAlign = component.align;
        setColumnProps({ width: colWidth, align: colElementAlign });
        setColumnElements(newColumnElements);
      } else if (componentType === "imageCarousel") {
        setColumnElements(await renderCarousel(component, isMobile ? "160px" : "400px"));
      } else if (componentType === "titleSection") {
        setColumnElements(renderTitleSection(component));
      } else if (componentType === "cardsComponent") {
        setColumnElements(await renderCardsSection(component, component.isFeatured, true, language));
      } else if (componentType === "imageGallery") {
        setColumnElements(await renderGallery(component));
      } else if (componentType === "imageComponent") {
        setColumnElements(await renderImage(component, language));
      } else if (componentType === "paragraphComponent") {
        setColumnElements(renderParagraph(component, language));
      } else if (componentType === "buttonComponent") {
        setColumnElements(renderButton(component, colors, language));
      } else if (componentType === "mapComponent") {
        setColumnElements(renderMap(component));
      }
    };

    renderComponents();
  }, [component, colors, isMobile, language]);
  return (
    <div
      className="column"
      style={{
        width: !isMobile ? `calc(${columnProps.width}% - 30px)` : "100%",
        alignItems: columnProps.align,
      }}
    >
      {columnElements}
    </div>
  );
}

export default Column;
