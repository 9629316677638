import React, { useEffect, useState, useContext } from "react";
import { BsCartPlusFill } from "react-icons/bs";
import { BsFillCartDashFill } from "react-icons/bs";
import { dimColor, priceFormatter } from "../../functions/utils";
import { addToCart } from "../../functions/addToCart";
import { TranslateContext } from "../../App";
import config from "../../configuration.json";

function Card({ type, content, configuration }) {
  const [hovered, setHovered] = useState(false);
  const [color, setColor] = useState("");
  const language = useContext(TranslateContext).language;

  // Set the initial addedToCart state based on the result
  const [addedToCart, setAddedToCart] = useState(false);

  function handleHover(state) {
    setHovered(state);
  }
  useEffect(() => {
    if (configuration) {
      setColor(configuration?.colors?.main?.hex);
    }

    // Retrieve the cartItems array from local storage
    let cartItems = localStorage.getItem("cartItems");
    cartItems = cartItems ? JSON.parse(cartItems) : [];

    // Check if the current item is in the cart
    const isAddedToCart = cartItems.some((cartItem) => cartItem.id === content.id);

    // Update the addedToCart state based on the result
    setAddedToCart(isAddedToCart);
  }, [content.id, configuration]);

  const handleAddToCart = (item) => {
    const isAddedToCart = addToCart(item);
    setAddedToCart(isAddedToCart);
  };

  function Type() {
    if (type === "general") {
      return (
        <div className="card">
          <a className="card-link" href={content.url}>
            <span className="hidden">Link to {content.title}</span>
          </a>
          <img className="card-image" src={content.image.src} alt={content.image.alt} />
          {content.category && (
            <div className="card-category" style={{ backgroundColor: content.category.color }}>
              <p>{content.category.title}</p>
            </div>
          )}
          <div className="card-inner">
            <div className="card-title">
              <p>{content.title}</p>
            </div>
          </div>
        </div>
      );
    } else if (type === "blog") {
      return (
        <div className="blog-card">
          <a className="card-link" href={content.url}>
            <span className="hidden">Link to {content.title}</span>
          </a>{" "}
          <img className="card-image" src={content.image.src} alt={content.image.alt} />
          {content.category && (
            <div className="card-category" style={{ backgroundColor: content.category.color }}>
              <p>{content.category.title}</p>
            </div>
          )}
          <div className="blog-card-inner">
            <p className="blog-card-date" style={{ color: "" }}>
              {content.publishDate}
            </p>
            <div className="card-title">
              <p>{content.title}</p>
            </div>
          </div>
        </div>
      );
    } else if (type === "product") {
      return (
        <div className="product-card">
          <a className="card-link" href={content.url}>
            <span className="hidden">Link to {content.title}</span>
          </a>{" "}
          <img className="product-card-image" src={content.image.src} alt={content.image.alt} />
          {content.category && (
            <div className="card-category" style={{ backgroundColor: content.category.color }}>
              <p>{content.category.title}</p>
            </div>
          )}
          <div className="product-card-inner">
            <div className="product-card-inner-left">
              <p className="product-card-title">{content.title}</p>
              <p className="product-card-description">{content.description}</p>
            </div>
            <div className="product-card-inner-right">
              <p style={{ color: configuration.colors.main.hex }} className="product-card-price">
                {`${priceFormatter.format(content.price)} ${config.texts.currency[language]}`}
              </p>
            </div>
          </div>
        </div>
      );
    } else if (type === "product-sm") {
      return (
        <div
          style={{
            filter: content.quantity === 0 ? "grayscale(100%)" : "none",
          }}
          className="product-card-sm"
        >
          <a className="card-link" href={content.url}>
            <span className="hidden">Link to {content.title}</span>
          </a>
          <img className="product-card-sm-image" src={content.image.src} alt={content.image.alt} />
          {content.category && (
            <div className="card-category" style={{ backgroundColor: content.category.color }}>
              <span>{content.category.title}</span>
            </div>
          )}
          <div className="product-card-sm-inner">
            <span className="product-card-sm-title">{content.title}</span>
            <div className="product-card-sm-price-container">
              <span
                className="product-card-sm-price"
                style={{
                  color: color,
                }}
              >
                {`${priceFormatter.format(content.price)} ${config.texts.currency[language]}`}
              </span>
              <button
                disabled={content.quantity === 0 ? true : false}
                className="product-card-sm-button"
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}
                style={{
                  backgroundColor: hovered ? dimColor(color, 20) : color,
                }}
                onClick={() => {
                  handleAddToCart(content);
                }}
              >
                {addedToCart ? <BsFillCartDashFill color="white" /> : <BsCartPlusFill color="white" />}
              </button>
            </div>
          </div>
        </div>
      );
    }
    return;
  }
  return configuration && <Type type={type} />;
}

export default Card;
