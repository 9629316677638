import { useState } from "react";

function SubNavItem({ color, hoverColor, url, text, isMobile }) {
  const [hovered, setHovered] = useState(false);
  function handleHover(state) {
    if (!isMobile) {
      setHovered(state);
    }
  }

  return (
    <a
      style={{
        color: hovered ? hoverColor : color,
      }}
      className="sub-nav-item"
      href={url}
      onMouseEnter={() => {
        handleHover(true);
      }}
      onMouseLeave={() => {
        handleHover(false);
      }}
    >
      {text}
    </a>
  );
}

export default SubNavItem;
