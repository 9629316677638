import React, { useContext } from "react";
import Image from "../Image/Image";
import "./Gallery.css";
import { TranslateContext } from "../../App";

function Gallery({ images }) {
  const language = useContext(TranslateContext).language;
  return (
    <div className="gallery">
      {images.map((image, index) => {
        return (
          <div className="gallery-item" key={`image-${index}`}>
            <Image source={image.source} altText={image.alt?.[language]} expandable={true} />
          </div>
        );
      })}
    </div>
  );
}

export default Gallery;
