import React from "react";

function LogoHolder({ src, color, hoverColor }) {
  if (src) {
    return (
      <a href="/">
        <img
          id="header-logo"
          className="header-logo"
          src={src}
          alt="logo"
        ></img>
      </a>
    );
  }
}

export default LogoHolder;
