import React, { useState, useEffect, useContext } from "react";
import { dimColor } from "../../functions/utils";
import "./Button.css";
import { ConfigContext } from "../../App";
import { Link } from "react-router-dom";

function Button({ text, url, openInNewTab, type, onClick }) {
  const configuration = useContext(ConfigContext);
  const [hovered, setHovered] = useState(false);
  const [buttonClass, setButtonClass] = useState("");
  const [buttonClassInner, setButtonClassInner] = useState("");
  const [color, setColor] = useState("");

  useEffect(() => {
    setColor(configuration.colors?.main?.hex);
    if (type === "small") {
      setButtonClass("button-sm");
      setButtonClassInner("button-sm-inner");
    } else if (type === "medium") {
      setButtonClass("button-md");
      setButtonClassInner("button-md-inner");
    } else if (type === "large") {
      setButtonClass("button-lg");
      setButtonClassInner("button-lg-inner");
    } else {
      setButtonClass("button-md");
      setButtonClassInner("button-md-inner");
    }
  }, [configuration, type]);

  function handleHover(state) {
    setHovered(state);
  }

  const target = openInNewTab ? "_blank" : "_self";

  const ButtonType = () => {
    if (openInNewTab) {
      return (
        <a
          className={buttonClass}
          onMouseEnter={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
          href={url}
          target={target}
        >
          <button
            className={buttonClassInner}
            style={{
              backgroundColor: hovered ? dimColor(color, 20) : color,
            }}
          >
            {text}
          </button>
        </a>
      );
    } else {
      return (
        <Link to={url}>
          <button
            className={buttonClass}
            onMouseEnter={() => handleHover(true)}
            onMouseLeave={() => handleHover(false)}
            onClick={onClick}
          >
            <span
              className={buttonClassInner}
              style={{
                backgroundColor: hovered ? dimColor(color, 20) : color,
              }}
            >
              {text}
            </span>
          </button>
        </Link>
      );
    }
  };

  return <ButtonType />;
}

export default Button;
