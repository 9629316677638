import { configurationGROQ, homePageGROQ, pageContentGROQ } from "./GROQ";
import { createSocialMediaArray, createNavItemsArray, fetchData } from "./utils";

export const fetchConfiguration = async () => {
  const configuration = await fetchData(configurationGROQ);

  const socialMediaFromSettings = configuration.settings.socialMedia;
  const socialMediaArray = createSocialMediaArray(socialMediaFromSettings);
  const navItemsArray = await createNavItemsArray(configuration.navigation.menu);
  const navigationArray = navItemsArray.concat(socialMediaArray);

  return {
    navigation: navigationArray,
    configuration: configuration.settings,
    allPages: configuration.allPages,
  };
};

export const fetchHomePageContent = async () => {
  const content = await fetchData(homePageGROQ);
  return content;
};

export const fetchProductPageContent = async (slug) => {
  const content = await fetchData(pageContentGROQ("product", slug));
  return content;
};

export const fetchPageContent = async (type, slug) => {
  const content = await fetchData(pageContentGROQ(type, slug));
  return content;
};
