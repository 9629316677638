import React, { useEffect, useContext, useState } from "react";
import Text from "../components/Paragraph/Text.js";
import { ConfigContext } from "../App.js";
import { fetchData } from "../functions/utils.js";
import { pageContentGROQ } from "../functions/GROQ.js";
import { renderCardsSection } from "../functions/renderComponent.js";
import { Helmet } from "react-helmet-async";
import Loading from "../components/Loading/Loading.js";
import { TranslateContext } from "../App.js";

function Store() {
  const configuration = useContext(ConfigContext);
  const [pageContent, setPageContent] = useState({});
  const [featured, setFeatured] = useState(null);
  const [allProducts, setAllProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const language = useContext(TranslateContext).language;

  useEffect(() => {
    const fetchAndSetPageContent = async () => {
      setIsLoading(true);
      const response = await fetchData(pageContentGROQ("store"));
      setPageContent(response.store);

      if (response.products) {
        const products = {
          cardProductSection: response.products,
          cardType: "product-sm",
          hasFilter: true,
        };

        setAllProducts(await renderCardsSection(products, false, false, language));
      }

      if (response.store.featured) {
        let featuredSection = {
          title: null,
          button: { link: null, size: "medium", text: null },
          cardProductSection: [],
          cardType: "product",
          hasFilter: false,
        };
        if (response.store.featured.title[language]) {
          featuredSection.title = response.store.featured.title[language];
        }
        if (response.store.featured.button) {
          if (response.store.featured.button.link) {
            featuredSection.button.link = response.store.featured.button.link;
          }
          if (response.store.featured.button.text) {
            featuredSection.button.text = response.store.featured.button.text[language];
          }
        }
        if (response.store.featured.products) {
          featuredSection.cardProductSection = response.store.featured.products;
        }

        setFeatured(await renderCardsSection(featuredSection, true, true, language));
      }
      setIsLoading(false);
    };

    fetchAndSetPageContent();
  }, [language]);

  if (isLoading) {
    return <Loading />;
  }
  return (
    pageContent && (
      <>
        <Helmet>
          <title>{pageContent.title[language]}</title>
          <meta name="description" content={pageContent.metaDescription[language]} />
        </Helmet>
        <div className="row">
          <div className="row-container">
            <div className="column">
              <h1 className="h1-title" style={{ color: configuration.colors.secondary.hex }}>
                {pageContent.title[language]}
              </h1>
              {pageContent.onPageDescription && (
                <Text content={pageContent.onPageDescription[language]} align={pageContent.onPageDescription.justify} />
              )}
              {allProducts}
              {featured}
            </div>
          </div>
        </div>
      </>
    )
  );
}

export default Store;
