import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

function Map({ lat, lng, zoom, width }) {
  return (
    <LoadScript googleMapsApiKey="AIzaSyCFQXfpj2yLuT4-zBGVI2bUEGxDcqECDfk">
      <GoogleMap
        mapContainerStyle={{ width: width ? width : "100%", height: "400px" }}
        center={{ lat: lat, lng: lng }}
        zoom={zoom}
      >
        <Marker position={{ lat: lat, lng: lng }} />
      </GoogleMap>
    </LoadScript>
  );
}

export default Map;
