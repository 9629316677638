import React, { useState } from "react";
import "./Image.css";

function Image({ source, height, altText, expandable }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    if (expandable) {
      setIsExpanded(!isExpanded);
      document.body.style.overflow = isExpanded ? "auto" : "hidden";
    }
  };

  return (
    <>
      <img
        className="image"
        src={source}
        alt={altText}
        onClick={handleClick}
        style={{ height: height, borderRadius: 0 }}
      />
      {isExpanded && (
        <div className="image-fullscreen-overlay" onClick={handleClick}>
          <div className="image-fullscreen-holder">
            <img src={source} alt={altText} className="image-expanded" />
          </div>
        </div>
      )}
    </>
  );
}

export default Image;
