import NavItem from "./NavItem";
import Hamburger from "./Hamburger";
import { TranslateContext } from "../../../App";
import { useContext } from "react";

function Navigation({ navItems, color, hoverColor, mobileMenuActive, isMobile, bgColor, onClick }) {
  const language = useContext(TranslateContext).language;

  if (isMobile) {
    return <Hamburger color={color} hoverColor={hoverColor} active={mobileMenuActive} onClick={onClick} />;
  } else {
    return navItems.map((item) => {
      return (
        <NavItem
          key={`nav-item-${item.name[language]}`}
          item={item}
          color={color}
          hoverColor={hoverColor}
          isMobile={isMobile}
          bgColor={bgColor}
        />
      );
    });
  }
}

export default Navigation;
