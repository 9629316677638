import { createClient } from "@sanity/client";

export default createClient({
  projectId: "dylvbz06",
  dataset: "production",
  useCdn: false,
  apiVersion: "v1",
  //protect this token via environment variables or netlify variables
  token: process.env.REACT_APP_SANITY_TOKEN,
});

export const tokens = {
  emailjs_init: process.env.REACT_APP_EMAILJS_INIT,
  emailjs_service_id: process.env.REACT_APP_EMAILJS_SERVICE_ID,
  emailjs_template_id_client: process.env.REACT_APP_EMAILJS_TEMPLATE_ID_CLIENT,
  emailjs_template_id_summary: process.env.REACT_APP_EMAILJS_TEMPLATE_ID_SUMMARY,
  brevo_api_key: process.env.REACT_APP_BREVO_API_KEY,
  brevo_template_id: process.env.REACT_APP_BREVO_TEMPLATE_ID,
};
