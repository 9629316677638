import React, { useState, useContext, useEffect } from "react";
import { FaShoppingCart } from "react-icons/fa";
import { ConfigContext } from "../../App";
import { Link } from "react-router-dom";
import "./Cart.css";

function Cart() {
  const configuration = useContext(ConfigContext);
  const [itemCounter, setItemCounter] = useState(0);

  useEffect(() => {
    const handleStorageChange = () => {
      const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      let totalQuantity = 0;
      for (const item of cartItems) {
        totalQuantity += item.cartQuantity;
      }
      setItemCounter(totalQuantity);
    };

    // Update itemCounter when component mounts
    handleStorageChange();

    // Update itemCounter when local storage changes
    window.addEventListener("cartItemsUpdated", handleStorageChange);

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener("cartItemsUpdated", handleStorageChange);
    };
  }, []);

  return (
    itemCounter > 0 && (
      <Link to="/checkout">
        <div className="cart group">
          <FaShoppingCart color="white" size={26} />
          <div
            className="cart-counter"
            style={{ backgroundColor: configuration.colors?.main?.hex }}
          >
            <span>{itemCounter}</span>
          </div>
        </div>
      </Link>
    )
  );
}

export default Cart;
